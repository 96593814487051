export default {
  items: [
    {
      name: 'Invoice Tools',
      url: '/collapse-default',
      icon: 'fas fa-tools',
      children:
      [
        {
          name: 'Invoices',
          url: '/pm',
          icon: 'fa fa-search',
        },
        {
          name: 'Master Invoice',
          icon: 'fa fa-file-chart-line',
          url: '/pm/master-invoice',
        },
        {
          name: 'Investigation Table',
          icon: 'fa fa-table',
          url: '/pm/investigation-table',
        },
      ]
    },

    {
      name: 'All Projects',
      url: '/pm/projects',
      icon: 'fa fa-layer-group',
    },
    {
      name: 'Vendor Management',
      url: '/collapse-default',
      icon: 'fa fa-chart-line',
      children: [
        {
          name: 'Vendor',
          url: '/pm/all-vendor',
          icon: 'fa fa-cog',
        },
        {
          name: 'Invite Vendor',
          url: '/pm/invite',
          icon: 'fa fa-cog',
        },
      ]
    },
    {
      name: 'Service Estimates',
      url: '/pm/service-estimates',
      icon: 'fa fa-edit',
    },
    {
      name: 'Cost Code Buckets',
      url: '/pm/buckets',
      icon: 'fa fa-fill',
    },
    {
      name: 'Charts',
      url: '/collapse-default',
      icon: 'fa fa-chart-line',
      children: [
        {
          name: 'Budget VS Actuals',
          url: '/pm/chart-1',
          icon: 'fa fa-chart-bar',
        },
        {
          name: 'Actual Well Cost',
          url: '/pm/chart-2',
          icon: 'fa fa-chart-bar',
        },
        {
          name: 'Actual Project Cost',
          url: '/pm/chart-3',
          icon: 'fa fa-chart-bar',
        },
        {
          name: 'Invoice Chart',
          url: '/pm/invoice-chart',
          icon: 'fa fa-chart-bar',
        },
        {
          name: 'Bucket Chart',
          url: '/pm/chart-buckets',
          icon: 'fa fa-chart-bar',
        },
      ]
    },
  ]
}
